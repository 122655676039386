import React from "react";
import { CarSearch } from "@capone/portal";
import {
  CorporateTypography,
  getShouldShowAnnualTravelCreditBanner,
  useDeviceTypes,
} from "halifax";
import {
  CallState,
  cardsToShowWithPriorityOnRewardsBanner,
  RewardsAccount,
  Tenant,
  WalletDetailsResponse,
} from "redmond";
import {
  CORP_HOMEPAGE_SUBTITLE,
  CORP_HOMEPAGE_TITLE,
  CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT,
  isCaponeTenant,
  isCorpTenant,
  useUserContext,
} from "@capone/common";
import { Skeleton } from "@material-ui/lab";
import { useExperimentsById } from "@capone/experiments";
import styles from "./styles.module.scss";
import { LandingPageType, MobileLandingPage } from "../MobileLandingPage";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_CROSS_SELL_V3,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_VARIANTS,
  useExperiments,
} from "../../context/experiments";
import { HomepageAnnualTravelCredit } from "../HomepageAnnualTravelCredit";
import config from "../../utils/config";
import { HomepageHotelCrossSell } from "../HomepageHotelCrossSell";
import { RecentlyViewedAndSearched } from "../RecentlyViewedAndSearched";
import { CrossSellProps } from "../Body/cross-sell-helpers";
import { RecentlyViewedAndSearchedProps } from "../Body/recently-viewed-and-searched-helpers";
import {
  EARN_ENHANCEMENT_TITLE,
  PARADISE_TITLE,
  SUBTITLE,
  TITLE,
} from "./textConstants";

type HopePageType = {
  type: LandingPageType;
  rewardsAccounts: RewardsAccount[];
  fetchRewardsAccountsCallState: CallState;
  walletDetails: WalletDetailsResponse | null;
  crossSellProps: CrossSellProps | null;
  recentlyViewedAndSearchedProps: RecentlyViewedAndSearchedProps | null;
  recentlyViewedAndSearchedLoaded: boolean;
};
/**
 * Component to render all home page products
 * TODO: implement funnel handling (cars, flights, hotels, etc)
 */
export const HomePage = ({
  type,
  rewardsAccounts,
  fetchRewardsAccountsCallState,
  walletDetails,
  crossSellProps,
  recentlyViewedAndSearchedProps,
  recentlyViewedAndSearchedLoaded,
}: HopePageType) => {
  const { matchesMobile } = useDeviceTypes();

  const { sessionInfo } = useUserContext(config.TENANT);

  const expState = useExperiments();

  const isHotelCrossSellExperiment =
    getExperimentVariant(
      expState.experiments,
      HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT
    ) === AVAILABLE;

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const isHotelCrossSellV3Enabled =
    getExperimentVariantCustomVariants(
      expState.experiments,
      HOTEL_CROSS_SELL_V3,
      HOTEL_CROSS_SELL_V3_VARIANTS
    ) !== CONTROL;

  const isRecentlyViewedDesktopP0Experiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
      RECENTLY_VIEWED_DESKTOP_P0_VARIANTS
    ) !== CONTROL;

  const isCorpOnboardingRevampEnabled =
    isCorpTenant(config.TENANT) &&
    (getExperimentVariant(expState.experiments, "corp-onboarding-revamp") ===
      "available" ||
      getExperimentVariant(expState.experiments, "corp-onboarding-revamp") ===
        "debug");

  const showDBCCustomHeader =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const { shouldShowVentureBanner, shouldShowVentureXBanner } =
    getShouldShowAnnualTravelCreditBanner(
      rewardsAccounts,
      walletDetails?.creditBreakdown
    );

  const showAnnualTravelCredit =
    isAnnualTravelCreditsExperiment &&
    !!walletDetails &&
    (shouldShowVentureBanner || shouldShowVentureXBanner) &&
    !!sessionInfo?.userInfo.firstName;

  const showCrossSellV3UI =
    isHotelCrossSellV3Enabled && !!crossSellProps?.tysonsOffer;

  const largestValueAccount = React.useMemo(
    () =>
      [...rewardsAccounts].sort((prev, current) => {
        const earnMultiplier = (account: RewardsAccount) =>
          prev.earn.flightsMultiplier && current.earn.flightsMultiplier
            ? account.earn.flightsMultiplier
            : account.earn.carsMultiplier ?? -1;
        if (earnMultiplier(current) === earnMultiplier(prev)) {
          return cardsToShowWithPriorityOnRewardsBanner.includes(
            current.productDisplayName.toLowerCase()
          )
            ? 1
            : -1;
        }
        return earnMultiplier(current) - earnMultiplier(prev);
      })[0],
    [rewardsAccounts]
  );

  const hasEarnToDisplay =
    !!largestValueAccount && !!largestValueAccount.earn.carsMultiplier;

  const getTitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      const title = showDBCCustomHeader
        ? CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT
        : CORP_HOMEPAGE_TITLE;

      return isCorpOnboardingRevampEnabled ? (
        <CorporateTypography variant="h1">{title}</CorporateTypography>
      ) : (
        title
      );
    }
    if (
      fetchRewardsAccountsCallState === CallState.Success &&
      hasEarnToDisplay
    ) {
      return EARN_ENHANCEMENT_TITLE(
        largestValueAccount.earn.carsMultiplier,
        largestValueAccount.productDisplayName,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency
      );
    }
    if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      if (
        largestValueAccount?.productDisplayName
          .toLowerCase()
          .includes("paradise")
      ) {
        return <span className="font-regular">{PARADISE_TITLE}</span>;
      }
      return <span className="font-regular">{TITLE}</span>;
    }
    return <Skeleton className="title-loading" />;
  };

  const getSubtitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return isCorpOnboardingRevampEnabled ? (
        <CorporateTypography variant="h5" fontWeight="light">
          {CORP_HOMEPAGE_SUBTITLE}
        </CorporateTypography>
      ) : (
        CORP_HOMEPAGE_SUBTITLE
      );
    }
    if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      return SUBTITLE;
    }
    return <Skeleton className="subtitle-loading" />;
  };

  return matchesMobile ? (
    <MobileLandingPage
      type={type}
      rewardsAccounts={rewardsAccounts}
      fetchRewardsAccountsCallState={fetchRewardsAccountsCallState}
      recentlyViewedAndSearchedLoaded={recentlyViewedAndSearchedLoaded}
    />
  ) : (
    <>
      <div className={styles["car-search-container"]}>
        <div className={styles["car-search-heading"]}>
          {getTitleToDisplay(config.TENANT)}
        </div>
        <div className={styles["car-search-subtitle"]}>
          {getSubtitleToDisplay(config.TENANT)}
        </div>
        <CarSearch className={styles.search} showRecentSearches />
      </div>
      {showAnnualTravelCredit && (
        <HomepageAnnualTravelCredit
          firstName={sessionInfo.userInfo.firstName}
          rewardsAccounts={rewardsAccounts}
          walletDetails={walletDetails}
          isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
        />
      )}
      {isHotelCrossSellExperiment && showCrossSellV3UI && crossSellProps ? (
        <HomepageHotelCrossSell
          searchLocationResult={crossSellProps.searchLocationResult}
          fromDate={crossSellProps.fromDate}
          untilDate={crossSellProps.untilDate}
          adultCount={crossSellProps.adultCount}
          childAges={crossSellProps.childAges}
          largestValueAccount={crossSellProps.largestValueAccount}
          latency={crossSellProps.latency}
          bannerText={crossSellProps.bannerText}
          tysonsOffer={crossSellProps.tysonsOffer}
        />
      ) : null}

      {(() => {
        switch (true) {
          case !!recentlyViewedAndSearchedProps &&
            isRecentlyViewedDesktopP0Experiment &&
            !!rewardsAccounts.length:
            return isCaponeTenant(config.TENANT) ? (
              <RecentlyViewedAndSearched
                recentSearchesAndViews={
                  recentlyViewedAndSearchedProps!.recentSearchesAndViews
                }
                largestValueAccount={
                  recentlyViewedAndSearchedProps!.largestValueAccount
                }
                latency={recentlyViewedAndSearchedProps!.latency}
                negativeMargin={recentlyViewedAndSearchedProps!.negativeMargin}
                loading={recentlyViewedAndSearchedLoaded}
                showSkeleton={false}
              />
            ) : (
              <div>
                <RecentlyViewedAndSearched
                  recentSearchesAndViews={
                    recentlyViewedAndSearchedProps!.recentSearchesAndViews
                  }
                  largestValueAccount={
                    recentlyViewedAndSearchedProps!.largestValueAccount
                  }
                  latency={recentlyViewedAndSearchedProps!.latency}
                  negativeMargin={
                    recentlyViewedAndSearchedProps!.negativeMargin
                  }
                  loading={recentlyViewedAndSearchedLoaded}
                  showSkeleton={isCorpOnboardingRevampEnabled}
                />
              </div>
            );
          default:
            return null;
        }
      })()}

      {isHotelCrossSellExperiment && !showCrossSellV3UI && crossSellProps ? (
        <HomepageHotelCrossSell
          searchLocationResult={crossSellProps.searchLocationResult}
          fromDate={crossSellProps.fromDate}
          untilDate={crossSellProps.untilDate}
          adultCount={crossSellProps.adultCount}
          childAges={crossSellProps.childAges}
          largestValueAccount={crossSellProps.largestValueAccount}
          latency={crossSellProps.latency}
          bannerText={crossSellProps.bannerText}
          tysonsOffer={crossSellProps.tysonsOffer}
        />
      ) : null}
    </>
  );
};

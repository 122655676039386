import type { CfarRateQuote } from "@b2bportal/lodging-api";
import {
  HotelCfarQuote,
  LodgingPriceFreezeOfferResult,
  HotelPriceFreezeOfferData,
  PaymentPriceQuoteRequestEnum,
  PaymentSessionToken,
  Person,
  QuoteId,
  HotelPriceFreezeRoomData,
} from "../apis";
import { AdditionalInfo } from "../apis/tysons/payment-machine";
import {
  OfferApplicabilityResponse,
  TravelWalletOffer,
} from "../apis/tysons/wallet";
import { HotelCfarQuoteAdditionalInfo } from "../apis/tysons";
import {
  Amount,
  Coordinates,
  DateRange,
  FiatAmount,
  FiatPrice,
  LocationDescriptor,
  Prices,
  TrackingProperties,
  Uuid,
} from "../common";
import { HotelItineraryState } from "../trips-module";
import { SearchRefinementOptions } from "../packages-module";

export enum AmenityEnum {
  // These are impossibly fast search amenities
  AccessibleBathroom = "AccessibleBathroom",
  AdultsOnly = "AdultsOnly",
  AirportTransportationAvailableGeneral = "AirportTransportationAvailableGeneral",
  AtmBanking = "AtmBanking",
  BarOrLounge = "BarOrLounge",
  BrailleOrRaisedSignage = "BrailleOrRaisedSignage",
  BusinessCenter = "BusinessCenter",
  Casino = "Casino",
  ComputerStation = "ComputerStation",
  ConciergeServices = "ConciergeServices",
  ConferenceSpace = "ConferenceSpace",
  DryCleaningLaundryService = "DryCleaningLaundryService",
  ElectricCarCharging = "ElectricCarCharging",
  ExpressCheckIn = "ExpressCheckIn",
  FitnessFacilities = "FitnessFacilities",
  FreeAirportTransportation = "FreeAirportTransportation",
  FreeBreakfast = "FreeBreakfast",
  FreeSelfParking = "FreeSelfParking",
  FreeWifi = "FreeWifi",
  FullServiceSpa = "FullServiceSpa",
  Garden = "Garden",
  HairSalon = "HairSalon",
  HikingOrBikingNearby = "HikingOrBikingNearby",
  IndoorPool = "IndoorPool",
  InRoomAccessibility = "InRoomAccessibility",
  LaundryFacilities = "LaundryFacilities",
  LuggageStorage = "LuggageStorage",
  MeetingRooms = "MeetingRooms",
  NumberOfBarsOrLounges = "NumberOfBarsOrLounges",
  NumberOfRestaurants = "NumberOfRestaurants",
  OutdoorPool = "OutdoorPool",
  OutdoorSeasonalPool = "OutdoorSeasonalPool",
  PaidBreakfast = "PaidBreakfast",
  PaidSelfParking = "PaidSelfParking",
  PaidValetParking = "PaidValetParking",
  ParkingAvailableGeneral = "ParkingAvailableGeneral",
  PetsAllowed = "PetsAllowed",
  PetsAllowedNoFees = "PetsAllowedNoFees",
  PetsOnlyCats = "PetsOnlyCats",
  PetsOnlyDogs = "PetsOnlyDogs",
  PetsOnlyDogsAndCats = "PetsOnlyDogsAndCats",
  PoolGeneral = "PoolGeneral",
  PoolsideBar = "PoolsideBar",
  PorterBellhop = "PorterBellhop",
  Restaurant = "Restaurant",
  RollInShower = "RollInShower",
  SafeDepositBoxFrontDesk = "SafeDepositBoxFrontDesk",
  Sauna = "Sauna",
  SnackOrDeli = "SnackOrDeli",
  SomeAccessibilityFeaturesAvailableGeneral = "SomeAccessibilityFeaturesAvailableGeneral",
  SpaTreatmentRoom = "SpaTreatmentRoom",
  SteamRoom = "SteamRoom",
  SwimmingPool = "SwimmingPool",
  Terrace = "Terrace",
  ToursTicketAssistance = "ToursTicketAssistance",
  TwentyFourHourFitnessFacilities = "TwentyFourHourFitnessFacilities",
  TwentyFourHourFrontDesk = "TwentyFourHourFrontDesk",
  WheelchairAccessibleParking = "WheelchairAccessibleParking",
  WheelchairAccessiblePathOfTravel = "WheelchairAccessiblePathOfTravel",
}

export interface AmenityStatus {
  amenity: AmenityEnum;
  displayName: string;
  details?: string;
}

export interface Approximate extends ILodgingLocationBase {
  LodgingLocation: LodgingLocationEnum.Approximate;
}

export enum LodgingCollectionEnum {
  Premier = "Premier",
  Lifestyle = "Lifestyle",
  NoCollection = "NoCollection",
}

export type AvailabilityRequest = FollowUpSearch | InitialSearch;

export interface IAvailabilityRequestBase {
  AvailabilityRequest: AvailabilityRequestEnum;
  excludeNonLuxuryLodgings?: boolean;
  lodgingCollection?: LodgingCollectionEnum;
}

export enum AvailabilityRequestEnum {
  FollowUpSearch = "FollowUpSearch",
  InitialSearch = "InitialSearch",
}

export type AvailabilityResponse = Initial | More;

export interface IAvailabilityResponseBase {
  AvailabilityResponse: AvailabilityResponseEnum;
  trackingPropertiesV2?: TrackingProperties;
  centroid?: Coordinates;
}

export enum AvailabilityResponseEnum {
  Initial = "Initial",
  More = "More",
}

export enum CategoryEnum {
  Dining = "Dining",
  Exterior = "Exterior",
  PointOfInterest = "PointOfInterest",
  Pool = "Pool",
  View = "View",
  Fitness = "Fitness",
  Lobby = "Lobby",
  Interior = "Interior",
  Uncategorized = "Uncategorized",
  GuestRoom = "GuestRoom",
  Spa = "Spa",
  Amenity = "Amenity",
  Bar = "Bar",
  Primary = "Primary",
  SportsFacility = "SportsFacility",
}

export type CountryCode = string;

export interface DiscountHunting {
  webPrice: Prices;
  savingsAmount: Prices;
}

// manually changed its name from "Exact" to "LodgingAddressExact" to avoid duplication
export interface LodgingAddressExact extends ILodgingAddressBase {
  line1: string;
  line2?: string;
  LodgingAddress: LodgingAddressEnum.Exact;
}

export interface Exact extends ILodgingLocationBase {
  placeTypes: PlaceTypeEnum[];
  LodgingLocation: LodgingLocationEnum.Exact;
}

export interface FollowUpSearch extends IAvailabilityRequestBase {
  moreToken: string;
  progressiveConfig: ProgressiveConfig;
  AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch;
}

export interface GuestsSelection {
  adults: number;
  children: number[];
  rooms: number;
  pets?: number;
}

export enum HotelStarRatingEnum {
  NotAvailable = "NotAvailable",
  Three = "Three",
  One = "One",
  Two = "Two",
  Five = "Five",
  Four = "Four",
}
export interface RoomsSelection {
  numberOfRooms: number;
}

export interface IHotelDetailsResponseBase {
  Response: HotelDetailsResponseEnum;
  value: HotelDetailsResponseValue;
}

export enum HotelDetailsResponseEnum {
  Success = "Success",
  Failure = "Failure",
}
export type HotelDetailsResponse =
  | HotelDetailsResponseSuccess
  | HotelDetailsResponseFailure;

export interface HotelDetailsResponseSuccess extends IHotelDetailsResponseBase {
  value: HotelDetailsResponseValue;
}

export interface HotelDetailsResponseValue {
  details: LodgingData;
}

export interface HotelDetailsResponseFailure extends IHotelDetailsResponseBase {
  error: string;
}

export interface Initial extends IAvailabilityResponseBase {
  dates: DateRange;
  lodgings: Lodging[];
  nextPageToken?: string;
  AvailabilityResponse: AvailabilityResponseEnum.Initial;
  bestOverallOffer?: TravelWalletOffer;
  offerApplicabilityResponse?: OfferApplicabilityResponse;
  totalPropertyCount?: number;
  checkInInstructions?: CheckInInstructions;
}

export interface InitialSearch extends IAvailabilityRequestBase {
  lodgingSelection: LodgingSelection;
  stayDates: DateRange;
  guests: Omit<GuestsSelection, "rooms">;
  progressiveConfig: ProgressiveConfig;
  platform: Platform;
  AvailabilityRequest: AvailabilityRequestEnum.InitialSearch;
  rooms?: RoomsSelection;
}

export interface Location extends ILodgingSelectionBase {
  descriptor: LocationDescriptor;
  center?: Coordinates;
  LodgingSelection: LodgingSelectionEnum.Location;
}

export enum StayType {
  Hotels = "Hotels",
  Homes = "Homes",
}

export interface HomeListingDetails {
  listingContent?: HomeListingContent;
  listingTerms?: HomeListingTerms;
}

export interface HomeListingContent {
  listingLayout?: HomeListingLayout;
  homesStarRating?: number;
  homesUserRating?: HomeUserRating;
}

export interface HomeUserRating {
  score: number;
  numberOfReviews: number;
}

export interface HomeListingLayout {
  numberOfBathrooms?: number;
  numberOfBedrooms?: number;
}

export interface HomeListingTerms {
  maxOccupancy?: number;
}

export interface Lodging {
  lodging: LodgingData;
  description: string;
  price?: LodgingPrice;
  available?: boolean;
  isPreferred: boolean;
  isFreeCancel: boolean;
  isLuxuryCollection: boolean;
  lodgingCollection?: LodgingCollectionEnum;
  bestPromotionThisLodging?: LodgingPromotion;
  bestOfferThisLodging?: TravelWalletOffer;
  minNightOfStay?: number;
  lastViewed?: string;
  isLifestyleCollection?: boolean;
  trackingPropertiesV2?: TrackingProperties;
  loyaltyProgramCode?: string;
  homesListingDetails?: HomeListingDetails;
  stayType?: StayType;
  nthNightPromotion?: nthNightPromotion;
  hasCorporateRates?: boolean;
  isSponsored?: boolean;
}

export interface nthNightPromotion {
  isLocked: boolean;
  description?: string;
  tooltipDescription?: string;
  originalPricePerNight?: Prices;
  originalPriceTotal?: Prices;
  numFreeNight?: number;
  promotionStartDate?: string;
  promotionEndDate?: string;
  los?: number;
}

export interface LodgingPromotion {
  promotionType: LodgingPromotionType;
  discountAmount: Amount;
  discountPercentage?: number;
}

export enum LodgingPromotionType {
  DealOfTheDay = "DealOfTheDay",
  Generic = "Generic",
}

// manually changed its name from "Exact" to "LodgingAddressExact" to avoid duplication
export type LodgingAddress = LodgingAddressExact | Obfuscated;

export interface ILodgingAddressBase {
  LodgingAddress: LodgingAddressEnum;
}

export enum LodgingAddressEnum {
  Exact = "Exact",
  Obfuscated = "Obfuscated",
}

export interface LodgingComparePrice {
  source: string;
  nightlyPrice: Prices;
}

export interface IState {
  code: string;
  name: string;
}

export interface LodgingData {
  id: LodgingId;
  name: string;
  address: LodgingAddress;
  city: string;
  country: CountryCode;
  state?: IState;
  description: string;
  phone?: string;
  smsPhone?: string;
  starRating: HotelStarRatingEnum;
  score: LodgingRating;
  media: LodgingMediaAsset[];
  amenities: AmenityStatus[];
  tripAdvisorReviews?: TripAdvisorReviews;
  location: LodgingLocation;
  checkInInstructions?: LodgingAvailabilityCheckInInstructions;
  roomsInfo?: RoomInfo[]; // used in shop-details
  lodgingCollection?: LodgingCollectionEnum; // used in shop-details
}

export type LodgingId = string;

export interface LodgingIds extends ILodgingSelectionBase {
  lodgingIds: LodgingId[];
  preserveOrder?: boolean;
  LodgingSelection: LodgingSelectionEnum.LodgingIds;
}

export type LodgingLocation = Approximate | Exact;

export interface ILodgingLocationBase {
  coordinates: Coordinates;
  LodgingLocation: LodgingLocationEnum;
}

export enum LodgingLocationEnum {
  Approximate = "Approximate",
  Exact = "Exact",
}

export type LodgingMediaAsset = NonResizableImage | ResizableImage | Video;

export interface ILodgingMediaAssetBase {
  url: string;
  LodgingMediaAsset: LodgingMediaAssetEnum;
}

export enum LodgingMediaAssetEnum {
  NonResizableImage = "NonResizableImage",
  ResizableImage = "ResizableImage",
  Video = "Video",
}

export interface DiscountAwarePrices {
  priceWithUnmanagedDiscounts: Prices;
  priceWithAllDiscounts: Prices;
  priceWithNoDiscounts: Prices;
}

export enum LodgingPriceBreakdownEnum {
  CategorySummary = "CategorySummary",
  LineItemBreakdown = "LineItemBreakdown",
  TotalSummary = "TotalSummary",
}

export interface ILodgingPriceBreakdownBase {
  LodgingPriceBreakdown: LodgingPriceBreakdownEnum;
}

export interface CategorySummary extends ILodgingPriceBreakdownBase {
  LodgingPriceBreakdown: LodgingPriceBreakdownEnum.CategorySummary;
  subTotal: Prices;
  total: Prices;
  taxesAndFees?: Prices;
  discounts?: Prices;
}

export interface LineItemBreakdown extends ILodgingPriceBreakdownBase {
  LodgingPriceBreakdown: LodgingPriceBreakdownEnum.LineItemBreakdown;
  subTotal: Prices;
  taxAndFeeLineItems: { [key: string]: Prices };
  total: Prices;
  discountLineItems?: { [key: string]: Prices };
}

export interface TotalSummary extends ILodgingPriceBreakdownBase {
  LodgingPriceBreakdown: LodgingPriceBreakdownEnum.TotalSummary;
  total: Prices;
}

export type LodgingPriceBreakdown =
  | CategorySummary
  | LineItemBreakdown
  | TotalSummary;

export interface LodgingPrice {
  totalPrice: Prices;
  nightlyPrice: Prices;
  comparePrices: LodgingComparePrice[];
  opaqueShopRequest: OpaqueValue;
  providerName: string;
  discountHunting?: DiscountHunting;
  nightlyDiscountAware: DiscountAwarePrices;
  totalDiscountAware: DiscountAwarePrices;
  totalDiscountAwareTaxesAndFeesIncluded?: DiscountAwarePrices;
  tripTotal?: Prices;
  breakdown: LodgingPriceBreakdown;
  payAtHotelBreakdown?: LodgingPriceBreakdown;
  displayTaxesAndFeesIncluded?: boolean;
  nightlyPriceTaxesAndFeesIncluded?: Prices;
  nightlyDiscountAwareTaxesAndFeesIncluded?: DiscountAwarePrices;
  displayTaxesAndFeesIncludedNightlyPrice?: boolean;
  mealPlan?: MealPlan;
}

export type LodgingRating = number;

export type LodgingSelection = Location | LodgingIds | Market | Place;

export type ILodgingSelectionBase = {
  LodgingSelection: LodgingSelectionEnum;
};

export enum LodgingSelectionEnum {
  Location = "Location",
  LodgingIds = "LodgingIds",
  Market = "Market",
  Place = "Place",
}

export interface Market extends ILodgingSelectionBase {
  groupingKey: WatchGroupingKey;
  LodgingSelection: LodgingSelectionEnum.Market;
}

export interface MarketGroupingKey extends IWatchGroupingKeyBase {
  id: string;
  name: string;
  fullName: string;
  airport: string;
  dateRange: DateRange;
  WatchGroupingKey: WatchGroupingKeyEnum.MarketGroupingKey;
}

export interface MealPlan {
  kind: MealPlanKindEnum;
}

export enum MealPlanKindEnum {
  AllInclusive = "AllInclusive",
  Breakfast = "Breakfast",
  BreakfastFor1 = "BreakfastFor1",
  BreakfastFor2 = "BreakfastFor2",
  Dinner = "Dinner",
  FullBoard = "FullBoard",
  HalfBoard = "HalfBoard",
  Lunch = "Lunch",
  RoomOnly = "RoomOnly",
}

export interface More extends IAvailabilityResponseBase {
  lodgings: Lodging[];
  nextPageToken?: string;
  AvailabilityResponse: AvailabilityResponseEnum.More;
  bestOverallOffer?: TravelWalletOffer;
  offerApplicabilityResponse?: OfferApplicabilityResponse;
}

export interface NonResizableImage extends ILodgingMediaAssetBase {
  category?: CategoryEnum;
  LodgingMediaAsset: LodgingMediaAssetEnum.NonResizableImage;
}

export interface Obfuscated extends ILodgingAddressBase {
  city: string;
  stateOrProvince: StateOrProvince;
  LodgingAddress: LodgingAddressEnum.Obfuscated;
}

export type OpaqueValue = string;

export interface Place extends ILodgingSelectionBase {
  placeId: string;
  searchTerm: string;
  placeTypes: string[];
  LodgingSelection: LodgingSelectionEnum.Place;
}

export enum PlaceTypeEnum {
  Lodging = "Lodging",
}

export type Platform = PlatformEnum.Desktop | PlatformEnum.Mobile;

export enum PlatformEnum {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export interface ProgressiveConfig {
  pageSize?: number;
}

export interface ResizableImage extends ILodgingMediaAssetBase {
  category?: CategoryEnum;
  LodgingMediaAsset: LodgingMediaAssetEnum.ResizableImage;
}

export interface StateOrProvince {
  code?: string;
  name?: string;
}

export interface TripAdvisorCategory {
  categoryName: string;
  score: LodgingRating;
}

export interface TripAdvisorComment {
  text: string;
  author: string;
  created: string;
  score: LodgingRating;
  title: string;
}

export interface TripAdvisorReviews {
  reviewsCount: number;
  overallScore: LodgingRating;
  categories: TripAdvisorCategory[];
  comments: TripAdvisorComment[];
}

export interface Video extends ILodgingMediaAssetBase {
  thumbnail: string;
  LodgingMediaAsset: LodgingMediaAssetEnum.Video;
}

export type WatchGroupingKey = MarketGroupingKey;

export interface IWatchGroupingKeyBase {
  WatchGroupingKey: WatchGroupingKeyEnum;
}

export enum WatchGroupingKeyEnum {
  MarketGroupingKey,
}

/* Shop */

export interface ShopRequest {
  opaqueRequest: string;
  locationSearchTerm?: string;
}

export type ShopResponse =
  | ShopResponseEnum.Available
  | ShopResponseEnum.Failure
  | ShopResponseEnum.Unavailable;

export interface IShopResponseBase {
  ShopResponse: ShopResponse;
  lodgingCollection?: LodgingCollectionEnum;
}

export enum ShopResponseEnum {
  Available = "Available",
  Failure = "Failure",
  Unavailable = "Unavailable",
}

export enum CancellationReason {
  CaliforniaBill644 = "CaliforniaBill644",
}

export interface CancellationSummary {
  reasons: CancellationReason[];
}

export interface IShopResponseAvailable {
  lodgingId: string;
  lodgingPriceChange?: PriceChange;
  roomInfoProducts: RoomInfoProducts[];
  ShopResponse: ShopResponseEnum.Available;
  trackingPropertiesV2?: TrackingProperties;
  opaqueCfarQuotesRequest?: string;
  cancellationSummary?: CancellationSummary;
}

export interface IShopResponseFailure {
  ShopResponse: ShopResponseEnum.Failure;
}

export interface IShopResponseUnavailable {
  ShopResponse: ShopResponseEnum.Unavailable;
}

export enum BedTypeEnum {
  Murphy = "Murphy",
  Single = "Single",
  RunOfTheHouse = "RunOfTheHouse",
  Double = "Double",
  King = "King",
  Dorm = "Dorm",
  Twin = "Twin",
  Water = "Water",
  Queen = "Queen",
  Sofa = "Sofa",
  Full = "Full",
  Tatami = "Tatami",
  Futon = "Futon",
}

export interface BedTypeList {
  bedTypes: BedTypeWithDescription[];
  description: string;
}

export interface BedTypeWithDescription {
  bedType: BedTypeEnum;
  description: string;
}

export enum PriceChangeDirectionEnum {
  Decrease = "Decrease",
  Increase = "Increase",
}

export interface AmenityStatus {
  amenity: AmenityEnum;
  displayName: string;
  details?: string;
}

type RoomType =
  | RoomTypeEnum.Bedroom
  | RoomTypeEnum.CommonArea
  | RoomTypeEnum.LivingRoom
  | RoomTypeEnum.Loft;

export enum RoomTypeEnum {
  Bedroom = "Bedroom",
  CommonArea = "CommonArea",
  LivingRoom = "LivingRoom",
  Loft = "Loft",
}
export interface Room {
  roomType: RoomType;
  bedTypes: BedTypeList;
}

export type HotelCancellationPolicy = Cfar | NonRefundable | Refundable;

interface EffectiveRangeAvailable {
  startTime: string;
  endTime: string;
  EffectiveRange: EffectiveRangeEnum.Available;
}

interface EffectiveRangeNotAvailable {
  EffectiveRange: EffectiveRangeEnum.NotAvailable;
}

type EffectiveRange = EffectiveRangeAvailable | EffectiveRangeNotAvailable;

export enum EffectiveRangeEnum {
  Available = "Available",
  NotAvailable = "NotAvailable",
}

export interface HotelCancellationPolicyFullyRefundableV2 {
  primaryText: string;
  secondaryText?: string;
  cfarInfoBannerText?: string;
  effectiveRange: EffectiveRange;
  CancellationPolicyV2: HotelCancellationPolicyV2Enum.FullyRefundable;
}

export interface HotelCancellationPolicyNonRefundableV2 {
  primaryText: string;
  secondaryText?: string;
  cfarInfoBannerText?: string;
  effectiveRange: EffectiveRange;
  CancellationPolicyV2: HotelCancellationPolicyV2Enum.NonRefundable;
}

export interface HotelCancellationPolicyPartiallyRefundableV2 {
  primaryText: string;
  secondaryText?: string;
  cfarInfoBannerText?: string;
  effectiveRange: EffectiveRange;
  CancellationPolicyV2: HotelCancellationPolicyV2Enum.PartiallyRefundable;
}

export interface HotelCancellationPolicyUnknownV2 {
  primaryText: string;
  CancellationPolicyV2: HotelCancellationPolicyV2Enum.Unknown;
}

export enum HotelCancellationPolicyV2Enum {
  FullyRefundable = "FullyRefundable",
  NonRefundable = "NonRefundable",
  PartiallyRefundable = "PartiallyRefundable",
  Unknown = "Unknown",
}

export type HotelCancellationPolicyV2 =
  | HotelCancellationPolicyFullyRefundableV2
  | HotelCancellationPolicyNonRefundableV2
  | HotelCancellationPolicyPartiallyRefundableV2
  | HotelCancellationPolicyUnknownV2;

export interface Discount {
  value: Prices;
  description: string;
}

export interface Fees {
  hotelFees?: FiatPrice;
  resortFees?: FiatPrice;
  cityTaxes?: FiatPrice;
  total?: FiatPrice;
}

type PriceChangeDirection =
  | PriceChangeDirectionEnum.Decrease
  | PriceChangeDirectionEnum.Increase;

export interface PriceChange {
  direction: PriceChangeDirection;
  priceChangePercentage: number;
}

export interface ProviderOverrideText {
  taxRecoveryChargesAndServiceFees?: string;
}

export interface RoomInfo {
  roomId: string;
  name?: string;
  description?: string;
  maxAdults?: number;
  maxChildren?: number;
  beds: BedTypeList;
  amenities: AmenityStatus[];
  sizeSqFt?: number;
  media: LodgingMediaAsset[];
}

export interface RoomInfoProducts {
  roomInfo: RoomInfo;
  products: RoomProduct[];
  trackingPropertiesV2?: TrackingProperties;
}

export interface RoomInfoProductsWithTransformedIndexes
  extends RoomInfoProducts {
  products: RoomProductWithTransformedIndexes[];
}

export interface RoomProduct {
  providerName: string;
  providerOverrideText: ProviderOverrideText;
  bedTypes: Rooms;
  baseRate: Prices;
  sellRate: Prices;
  taxBreakdown: Taxes;
  cfarQuotes: CfarRateQuote[];
  feeBreakdown: Fees;
  discounts: Discount[];
  total: Prices;
  tripTotal: Prices;
  perNightBaseRate: Prices;
  perNightSellRate: Prices;
  perNightTotal: Prices;
  opaqueQuoteRequest: string;
  cancellationPolicy: HotelCancellationPolicy;
  supplierRateCategory?: SupplierRateCategory;
  roomName?: string;
  policies: CheckInPolicy[];
  nightlyDiscountAware: DiscountAwarePrices;
  totalDiscountAware: DiscountAwarePrices;
  trackingPropertiesV2?: TrackingProperties;
  cancellationPolicyV2?: HotelCancellationPolicyV2;
  rateId?: { value: string };
  nightlyDiscountAwareTaxesAndFeesIncluded?: DiscountAwarePrices;
  perNightSellRateTaxesAndFeesIncluded?: Prices;
  nthNightPromotion?: nthNightPromotion;
  isCorporate?: boolean;
  mealPlan?: MealPlan;
}

export enum RoomProductType {
  Default,
  RefundableRoom,
}

interface RoomProductBase {
  baseProduct: {
    roomIndex: number;
    productIndex: number;
    rateId?: { value: string };
  };
  variant: RoomProductType;
}

interface RoomProductDefault extends RoomProductBase {
  variant: RoomProductType.Default;
}

export interface ICancellationPolicyScenario {
  type: CancellationPolicyScenarioEnum;
}

/* 
  If there is a non-refundable and partially refundable option by the hotel the user will see
  a “Fully refundable by Capital One Travel until {X date}” refundable room; it will be built
  off the hotel’s partially refundable rate.

  for more details, see https://hopchat.slack.com/archives/C02LKB2MVFY/p1688747419628689
*/
export enum CancellationPolicyScenarioEnum {
  TwoHotelOptionsOneNonRefOnePartiallyRef,
  Other,
}

interface RoomProductRefundableRoom
  extends RoomProductBase,
    Omit<HotelCfarQuote, "id" | "CfarQuoteResult"> {
  quoteId: QuoteId;
  premiumPricesPerNight: Prices;
  cancellationPolicy: HotelCancellationPolicyV2 | null;
  cancellationPolicyScenario: ICancellationPolicyScenario;
  additionalInfo: HotelCfarQuoteAdditionalInfo | null;
  variant: RoomProductType.RefundableRoom;
}

export type RoomProductVariant = RoomProductDefault | RoomProductRefundableRoom;

/*
  note: this is a FE only model that's based off RoomProduct; it's created to
  fulfill the implementation of specific fintech products (e.g, Refundable Room)
  that are offered through the HotelShopRoomTypePickerRedesign UI component
*/
export interface RoomProductWithTransformedIndexes extends RoomProduct {
  roomProductVariant?: RoomProductVariant;
  priceFreezeOffer?: LodgingPriceFreezeOfferResult;
}

export interface Rooms {
  rooms: Room[];
  occupancy?: number;
}

export type SupplierRateCategory =
  | SupplierRateCategoryEnum.Getaroom_Mobile
  | SupplierRateCategoryEnum.HotelPlanner_unpubStrict
  | SupplierRateCategoryEnum.Scrape_Agoda
  | SupplierRateCategoryEnum.Roomer_minprice
  | SupplierRateCategoryEnum.Roomer_minprice_special_deal
  | SupplierRateCategoryEnum.Hotelbeds_Unknown
  | SupplierRateCategoryEnum.Bookohotel_Unknown
  | SupplierRateCategoryEnum.Booking_DealOfTheDay
  | SupplierRateCategoryEnum.Booking_LastMinuteDeal
  | SupplierRateCategoryEnum.Priceline_MemberDeal
  | SupplierRateCategoryEnum.Roomer_net_special_deal
  | SupplierRateCategoryEnum.Hotelbeds_Offer
  | SupplierRateCategoryEnum.Budapest_FencedEverWatchedBookedFlight
  | SupplierRateCategoryEnum.HotelPlanner_Public
  | SupplierRateCategoryEnum.Priceline_Booking
  | SupplierRateCategoryEnum.Hotelbeds_Select
  | SupplierRateCategoryEnum.W2m_Public
  | SupplierRateCategoryEnum.HotelPlanner_unpubMobile
  | SupplierRateCategoryEnum.Vrbo_PublicMobile
  | SupplierRateCategoryEnum.Expedia_PublicWeb
  | SupplierRateCategoryEnum.Scrape_Booking
  | SupplierRateCategoryEnum.Snaptravel_Restricted
  | SupplierRateCategoryEnum.Getaroom_Net_Public
  | SupplierRateCategoryEnum.Dhisco_Commission
  | SupplierRateCategoryEnum.Getaroom_Net_ClosedUserGroup
  | SupplierRateCategoryEnum.Olympiaeu_Public
  | SupplierRateCategoryEnum.Hotelbeds_Special
  | SupplierRateCategoryEnum.Scrape_Priceline
  | SupplierRateCategoryEnum.Budapest_Private
  | SupplierRateCategoryEnum.Expedia_ClosedUserGroup
  | SupplierRateCategoryEnum.Hotelbeds_Regular
  | SupplierRateCategoryEnum.Expedia_PublicMobile
  | SupplierRateCategoryEnum.HotelPlanner_unpubLight
  | SupplierRateCategoryEnum.HotelPlanner_packageReg
  | SupplierRateCategoryEnum.Priceline_ClosedUserGroup
  | SupplierRateCategoryEnum.Snaptravel_Public
  | SupplierRateCategoryEnum.Getaroom_Public
  | SupplierRateCategoryEnum.Getaroom_ClosedUserGroup
  | SupplierRateCategoryEnum.Getaroom_Net_Mobile
  | SupplierRateCategoryEnum.Booking_SecretDeal
  | SupplierRateCategoryEnum.Budapest_Public
  | SupplierRateCategoryEnum.Priceline_Flexible
  | SupplierRateCategoryEnum.Booking_Unknown
  | SupplierRateCategoryEnum.Dhisco_Net
  | SupplierRateCategoryEnum.Priceline_AdditionalRetail
  | SupplierRateCategoryEnum.HotelPlanner_packageStrict
  | SupplierRateCategoryEnum.Getaroom_Tonight
  | SupplierRateCategoryEnum.Scrape_Undetermined
  | SupplierRateCategoryEnum.Roomer_net
  | SupplierRateCategoryEnum.Priceline_Flexbenchmark
  | SupplierRateCategoryEnum.Scrape_Hotelbeds
  | SupplierRateCategoryEnum.Scrape_Expedia
  | SupplierRateCategoryEnum.Scrape_Snaptravel
  | SupplierRateCategoryEnum.Priceline_Private
  | SupplierRateCategoryEnum.Booking_SalesCampaign
  | SupplierRateCategoryEnum.Booking_Public
  | SupplierRateCategoryEnum.Priceline_ClosedUserGroup2
  | SupplierRateCategoryEnum.Priceline_Agoda
  | SupplierRateCategoryEnum.Priceline_Public
  | SupplierRateCategoryEnum.Hotelbeds_Package
  | SupplierRateCategoryEnum.HotelPlanner_unpubReg
  | SupplierRateCategoryEnum.Booking_SmartDeal
  | SupplierRateCategoryEnum.Getaroom_Net_Tonight
  | SupplierRateCategoryEnum.Agoda_Unknown
  | SupplierRateCategoryEnum.Booking_Mobile
  | SupplierRateCategoryEnum.Scrape_Getaroom
  | SupplierRateCategoryEnum.Priceline_MobileExclusive
  | SupplierRateCategoryEnum.Expedia_ExposedPackage
  | SupplierRateCategoryEnum.Booking_FlashDeal
  | SupplierRateCategoryEnum.Scrape_Hotelplanner;

export enum SupplierRateCategoryEnum {
  Hotelbeds_Regular = "Hotelbeds_Regular",
  Priceline_Flexible = "Priceline_Flexible",
  Priceline_Public = "Priceline_Public",
  Getaroom_ClosedUserGroup = "Getaroom_ClosedUserGroup",
  Priceline_Private = "Priceline_Private",
  Priceline_Agoda = "Priceline_Agoda",
  Booking_LastMinuteDeal = "Booking_LastMinuteDeal",
  Olympiaeu_Public = "Olympiaeu_Public",
  Bookohotel_Unknown = "Bookohotel_Unknown",
  Roomer_net_special_deal = "Roomer_net_special_deal",
  Booking_SalesCampaign = "Booking_SalesCampaign",
  Budapest_Private = "Budapest_Private",
  Getaroom_Tonight = "Getaroom_Tonight",
  Roomer_net = "Roomer_net",
  Booking_Mobile = "Booking_Mobile",
  Agoda_Unknown = "Agoda_Unknown",
  Hotelbeds_Select = "Hotelbeds_Select",
  Scrape_Undetermined = "Scrape_Undetermined",
  HotelPlanner_unpubLight = "HotelPlanner_unpubLight",
  Scrape_Agoda = "Scrape_Agoda",
  Expedia_ClosedUserGroup = "Expedia_ClosedUserGroup",
  Priceline_MemberDeal = "Priceline_MemberDeal",
  Snaptravel_Public = "Snaptravel_Public",
  Priceline_ClosedUserGroup = "Priceline_ClosedUserGroup",
  Roomer_minprice_special_deal = "Roomer_minprice_special_deal",
  Booking_SecretDeal = "Booking_SecretDeal",
  Scrape_Expedia = "Scrape_Expedia",
  Getaroom_Net_Public = "Getaroom_Net_Public",
  Vrbo_PublicMobile = "Vrbo_PublicMobile",
  Getaroom_Net_Mobile = "Getaroom_Net_Mobile",
  Budapest_FencedEverWatchedBookedFlight = "Budapest_FencedEverWatchedBookedFlight",
  Getaroom_Mobile = "Getaroom_Mobile",
  Expedia_PublicMobile = "Expedia_PublicMobile",
  HotelPlanner_packageStrict = "HotelPlanner_packageStrict",
  Priceline_ClosedUserGroup2 = "Priceline_ClosedUserGroup2",
  HotelPlanner_unpubStrict = "HotelPlanner_unpubStrict",
  Scrape_Booking = "Scrape_Booking",
  Priceline_AdditionalRetail = "Priceline_AdditionalRetail",
  Getaroom_Public = "Getaroom_Public",
  Priceline_Flexbenchmark = "Priceline_Flexbenchmark",
  HotelPlanner_packageReg = "HotelPlanner_packageReg",
  Expedia_PublicWeb = "Expedia_PublicWeb",
  Hotelbeds_Unknown = "Hotelbeds_Unknown",
  Priceline_Booking = "Priceline_Booking",
  Booking_Public = "Booking_Public",
  Scrape_Getaroom = "Scrape_Getaroom",
  Roomer_minprice = "Roomer_minprice",
  Priceline_MobileExclusive = "Priceline_MobileExclusive",
  Hotelbeds_Special = "Hotelbeds_Special",
  Scrape_Snaptravel = "Scrape_Snaptravel",
  Snaptravel_Restricted = "Snaptravel_Restricted",
  HotelPlanner_unpubReg = "HotelPlanner_unpubReg",
  Getaroom_Net_Tonight = "Getaroom_Net_Tonight",
  Dhisco_Net = "Dhisco_Net",
  Booking_Unknown = "Booking_Unknown",
  HotelPlanner_unpubMobile = "HotelPlanner_unpubMobile",
  Expedia_ExposedPackage = "Expedia_ExposedPackage",
  Budapest_Public = "Budapest_Public",
  Booking_SmartDeal = "Booking_SmartDeal",
  Dhisco_Commission = "Dhisco_Commission",
  Hotelbeds_Offer = "Hotelbeds_Offer",
  HotelPlanner_Public = "HotelPlanner_Public",
  Booking_FlashDeal = "Booking_FlashDeal",
  Hotelbeds_Package = "Hotelbeds_Package",
  Scrape_Hotelbeds = "Scrape_Hotelbeds",
  Booking_DealOfTheDay = "Booking_DealOfTheDay",
  W2m_Public = "W2m_Public",
  Scrape_Priceline = "Scrape_Priceline",
  Getaroom_Net_ClosedUserGroup = "Getaroom_Net_ClosedUserGroup",
  Scrape_Hotelplanner = "Scrape_Hotelplanner",
}

export enum CancellationPolicyEnum {
  Cfar = "Cfar",
  NonRefundable = "NonRefundable",
  Refundable = "Refundable",
  FullRefundable = "FullyRefundable",
}

export interface Taxes {
  taxRecoveryCharges?: Prices;
  salesTax?: Prices;
  propertyFee?: Prices;
}

/* Book */
export type IconType =
  | IconTypeEnum.Expiration
  | IconTypeEnum.Unavailable
  | IconTypeEnum.Info
  | IconTypeEnum.Money
  | IconTypeEnum.Check;

export enum IconTypeEnum {
  Expiration = "Expiration",
  Unavailable = "Unavailable",
  Info = "Info",
  Money = "Money",
  Check = "Check",
}

export interface Info {
  IconType: IconTypeEnum;
}

export interface PriceQuoteScheduleRequest {
  token: string;
  quoteRequest: string;
  personId: string;
  phoneNumber: string;
  emailAddress: string;
  delegatedTo?: string;
}

type PersonId = string;

export interface HotelQuoteScheduleRequest {
  token: string;
  quoteRequest: string;
  personId: PersonId;
  phoneNumber: string;
  emailAddress: string;
  dates?: DateRange;
  id?: LodgingId;
  name?: string;
  city?: string;
  country?: string;
  starRating?: HotelStarRatingEnum;
  providerName?: string;
  bedTypes?: BedTypeList;
  delegatedTo?: string;
  lodgingCollection?: LodgingCollectionEnum;
  additionalGuests?: PersonId[];
}

export type HotelPriceQuoteScheduleRequest =
  | HotelPriceQuoteScheduleRequestV1
  | HotelPriceQuoteScheduleRequestV2;

export interface HotelPriceQuoteScheduleRequestV1 {
  hotelQuoteRequest: HotelQuoteScheduleRequest;
  quoteDescription: string;
  PaymentPriceQuoteRequest: PaymentPriceQuoteRequestEnum;
}

export interface HotelPriceQuoteScheduleRequestV2 {
  hotelQuoteRequest: HotelQuoteScheduleRequest;
  hotelAncillaryIds: HotelAncillaryIds;
}

export interface HotelAncillaryIds {
  cfarId?: QuoteId;
}

export interface HotelPriceFreezePriceQuoteScheduleRequest {
  lodgingId: string;
  lodgingName: string;
  checkIn: string;
  checkOut: string;
  occupancy: number;
  offerData: HotelPriceFreezeOfferData;
  roomData: HotelPriceFreezeRoomData[];
  emailAddress: string;
}

export interface ScheduleResponseSuccess {}
export interface ScheduleResponseFailed {
  error: string;
}

export type ScheduleResponse = ScheduleResponseSuccess | ScheduleResponseFailed;

export interface Cfar {
  primaryText: string;
  coveragePercentage: number;
  perNightRefund?: Prices;
  taxExclusiveRefund: Prices;
  bookingAmount: Prices;
  refundAmount: Prices;
  cfarInfoBannerText?: string;
  CancellationPolicy: CancellationPolicyEnum.Cfar;
}

export interface NonRefundable {
  primaryText: string;
  secondaryText?: string;
  cfarInfoBannerText?: string;
  CancellationPolicy: CancellationPolicyEnum.NonRefundable;
}

export interface Refundable {
  primaryText: string;
  secondaryText?: string;
  cfarInfoBannerText?: string;
  CancellationPolicy: CancellationPolicyEnum.Refundable;
}

export interface Check {
  IconType: IconTypeEnum;
}

export interface CheckInInstructions {
  checkInTime?: CheckInTime;
  checkOutTime?: string;
  minimumAge?: number;
  acceptedCards: string[];
  policies: CheckInPolicy[];
}

export interface CheckInPolicy {
  title: string;
  text: string;
}

export type CheckInTime = CheckInTimeEnum.Anytime | CheckInTimeEnum.Restricted;

export enum CheckInTimeEnum {
  Anytime = "Anytime",
  Restricted = "Restricted",
}

export type Severity = SeverityEnum.Fatal | SeverityEnum.Warning;

export enum SeverityEnum {
  Fatal = "Fatal",
  Warning = "Warning",
}

export interface DetailedServerError {
  errorCode: string;
  severity: Severity;
}

export interface HotelPricingDiscount {
  value: Prices;
  description: string;
}

export interface HotelPricing {
  currency: string;
  rooms: Prices;
  taxes: Prices;
  taxBreakdown: Taxes;
  subtotal: Prices;
  discounts: HotelPricingDiscount[];
  grandTotal: FiatAmount;
  payNowTotal: Prices;
  tripTotal: Prices;
  feeBreakdown: Fees;
}

export interface PriceQuote {
  pricing: HotelPricing;
  priceChange?: PriceChange;
  cancellationPolicy: HotelCancellationPolicy;
  checkInInstructions: CheckInInstructions;
  lodgingCollection: LodgingCollectionEnum;
}

export interface SlimCreditCard {
  paymentId: string;
  createdAt: string;
  numberDisplay?: string;
  cardType: string;
  SlimPayment: SlimPaymentEnum;
}

export type SlimPayment = SlimCreditCard;

export enum SlimPaymentEnum {
  SlimCreditCard = "SlimCreditCard",
}

export enum HotelBookErrorType {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
  CheckInMinimumAgeNotMet = "CheckInMinimumAgeNotMet",
  PaymentError = "PaymentError",
  LackOfInventory = "LackOfInventory",
  RateNotAvailable = "RateNotAvailable",
  Inactivity = "Inactivity",
  RedemptionFailure = "RedemptionFailure",
}

export interface HotelBookErrorCode {
  code: HotelBookErrorType | string;
  message?: string;
}

export interface HotelBookPollFailed {
  purchaseResult: {
    errors: HotelBookErrorCode[];
    Response: HotelBookPollGetResultEnum.Failed;
  };
}

export interface HotelBookPollPending {
  purchaseResult: {
    Response: HotelBookPollGetResultEnum.Pending;
  };
}

export interface HotelBookPollSuccess {
  purchaseResult: {
    value: Reservation;
    Response: HotelBookPollGetResultEnum.Success;
  };
}

export interface HotelPriceQuotePollFailed {
  priceQuote: {
    errors: HotelBookErrorCode[];
    Response: HotelBookPollGetResultEnum.Failed;
  };
}

export interface HotelPriceQuotePollPending {
  priceQuote: {
    Response: HotelBookPollGetResultEnum.Pending;
  };
}

export interface HotelPriceQuotePollSuccess {
  priceQuote: {
    value: PriceQuote;
    Response: HotelBookPollGetResultEnum.Success;
  };
}

export interface BookedRooms {
  count: number;
  roomInfo: RoomInfo;
}

export enum HotelBookPollGetResultEnum {
  Success = "Success",
  Pending = "Pending",
  Failed = "Failed",
}

export type HotelBookPollResult =
  | HotelBookPollFailed
  | HotelBookPollPending
  | HotelBookPollSuccess;

export type HotelPriceQuotePollResult =
  | HotelPriceQuotePollFailed
  | HotelPriceQuotePollSuccess
  | HotelPriceQuotePollPending;

export interface ContractId {
  value: string;
}
export interface BookedAncillaryIds {
  cfarContractId?: ContractId;
}

export interface Reservation {
  additionalInfo?: AdditionalInfo;
  reservationBookingId: Uuid;
  reservationId: string;
  lodgingData: LodgingData;
  checkInDate: string;
  checkOutDate: string;
  createdAt: string;
  guests: Person[];
  pricing: HotelPricing;
  payment: SlimPayment;
  paymentSchedule?: string;
  paymentCurrency: string;
  providerOverrideText: ProviderOverrideText;
  bedTypes: Rooms;
  policies: CheckInPolicy[];
  cancellationPolicy: HotelCancellationPolicy;
  roomInfo?: RoomInfo;
  state: ReservationState;
  trackingPropertiesV2: TrackingProperties;
  bookedRooms: BookedRooms[];
  isLuxuryCollection: boolean;
  bookedAncillaryIds?: BookedAncillaryIds;
  lodgingCollection: LodgingCollectionEnum;
  hotelConfirmationCode: HotelConfirmationCode;
}

export interface ReservationState {
  ReservationState: HotelItineraryState;
}

export type SessionRequest =
  | SessionResponseEnum.Open
  | SessionResponseEnum.Close;

export enum SessionRequestEnum {
  Open = "Open",
  Close = "Close",
}

export type SessionResponse =
  | SessionResponseEnum.Open
  | SessionResponseEnum.Close;

export enum SessionResponseEnum {
  Open = "Open",
  Close = "Close",
}

export type SessionServiceState = "Valid" | InvalidSession;

export interface InvalidSession {
  code?: string;
  message?: number;
}

export interface TokenValue {
  token?: string;
  state: SessionServiceState;
}

export interface OpenSessionReponse {
  SessionResponse: SessionResponse;
  token: TokenValue;
}

export interface HotelBookPollRequest {
  token: PaymentSessionToken;
}

export interface HotelPriceQuotePollRequest {
  token: PaymentSessionToken;
}

export enum CodeEnum {
  ExcessiveActivity = "ExcessiveActivity",
  Unauthorized = "Unauthorized",
}

export enum HotelEntryTypeEnum {
  FLIGHTS_CHECKOUT = "cross_sell_hotel_flights_checkout",
  MY_TRIPS = "cross_sell_hotel_mytrips",
  HOMEPAGE = "cross_sell_hotel_homepage",
  SEARCH_DISTANCE_FROM = "search_distance_from",
  RECENTLY_VIEWED_HOTEL = "recently_viewed_hotel",
  RECENTLY_SEARCHED_HOTEL = "recently_searched_hotel",
  RECENTLY_SEARCH_AUTOCOMPLETE = "recently_searched_autocomplete",
  TRAVEL_SALE_LANDING = "travel_sale_landing",
  TRAVEL_SALE_HOMEPAGE = "travel_sale_homepage",
}

export enum HotelDetailsEntrySourceEnum {
  MAP = "map",
  LIST = "list",
}

export interface LodgingAvailabilityCheckInInstructions {
  checkInTime?: LodgingAvailabilityCheckInTime;
  checkOutTime?: string;
  minimumAge?: number;
  acceptedCards: string[];
  policies: CheckInPolicy[];
}

export interface LodgingAvailabilityCheckInTimeAnytime {
  CheckInTime: LodgingAvailabilityCheckInTimeEnum.Anytime;
}

export interface LodgingAvailabilityCheckInTimeRestricted {
  checkInTimeFrom?: string;
  checkInTimeUntil?: string;
  CheckInTime: LodgingAvailabilityCheckInTimeEnum.Restricted;
}

export type LodgingAvailabilityCheckInTime =
  | LodgingAvailabilityCheckInTimeAnytime
  | LodgingAvailabilityCheckInTimeRestricted;

export enum LodgingAvailabilityCheckInTimeEnum {
  Anytime = "Anytime",
  Restricted = "Restricted",
}

export interface HotelPriceCalendarRequest {
  lodgingSelection: LodgingSelection;
  startDate: string;
  lodgingCollection: LodgingCollectionEnum;
  platform: Platform;
}

export interface CalendarPriceBucket {
  legend: string;
  dates: string[];
}

export interface HotelPriceCalendarResponse {
  buckets: CalendarPriceBucket[];
}

/**
 * @type HotelConfirmationCode
 * hotel confirmation code provided by the hotel post-booking
 * @export
 */
export type HotelConfirmationCode =
  | HotelConfirmationConfirmed
  | HotelConfirmationPending
  | HotelConfirmationUnavailable;

export enum HotelConfirmationCodeEnum {
  HotelConfirmationConfirmed = "HotelConfirmationConfirmed",
  HotelConfirmationPending = "HotelConfirmationPending",
  HotelConfirmationUnavailable = "HotelConfirmationUnavailable",
}

/**
 * Hotel confirmation code provided directly from the hotel. Generally available only after booking is confirmed and < 2 weeks before check-in.

 * `rateOwner` - Rate provider for the hotel. Used primarily for tracking.
 * @export
 * @interface HotelConfirmationConfirmed
 */
export interface HotelConfirmationConfirmed {
  confirmationCode: string;
  /**
   * Rate provider for the hotel. Used primarily for tracking.
   * @type {string}
   * @memberof HotelConfirmationConfirmed
   */
  rateOwner: string;
  HotelConfirmationCode: HotelConfirmationCodeEnum;
}

/**
 * Hotel confirmation code is pending. We are expected to make a call to Hamburg to receive this at the expected date.

 * `expectedDate` - Expected date to receive the confirmation code.
 * `rateOwner` - Rate provider for the hotel. Used primarily for tracking
 * @export
 * @interface HotelConfirmationPending
 */
export interface HotelConfirmationPending {
  /**
   * Expected date to receive the confirmation code.
   * @type {string}
   * @memberof HotelConfirmationPending
   */
  expectedDate: string;
  /**
   * Rate provider for the hotel. Used primarily for tracking
   * @type {string}
   * @memberof HotelConfirmationPending
   */
  rateOwner: string;
  HotelConfirmationCode: HotelConfirmationCodeEnum;
}

/**
 * Hotel confirmation code is unavailable, generally due it not being available from the provider api.

 * `rateOwner` - Rate provider for the hotel. Used primarily for tracking
 * `reason` - Non-user facing reason for the code being unavailable. Used for debugging and tracking.
 * @export
 * @interface HotelConfirmationUnavailable
 */
export interface HotelConfirmationUnavailable {
  /**
   * Rate provider for the hotel. Used primarily for tracking
   * @type {string}
   * @memberof HotelConfirmationUnavailable
   */
  rateOwner: string;
  /**
   * Non-user facing reason for the code being unavailable. Used for debugging and tracking.
   * @type {string}
   * @memberof HotelConfirmationUnavailable
   */
  reason?: string;
  HotelConfirmationCode: HotelConfirmationCodeEnum;
}

export interface LodgingTokenRequest {
  lodgingCollection: LodgingCollectionEnum;
  searchRefinementOptions?: SearchRefinementOptions;
  progressiveConfig: ProgressiveConfig;
  stayDates: DateRange;
  lodgingId: string;
  guests: Omit<GuestsSelection, "rooms">;
  rooms: RoomsSelection;
  opaqueShopRequest: string;
  platform: Platform;
}

export interface LodgingTokenResponse {
  opaqueShopRequest: string;
}
